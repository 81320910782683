<template>
  <li>
    <span
      v-for="regimen, index in treatment.regimens"
      :key="`${index}-${regimen.drug.id}-${$i18n.locale}`"
    >
      <a
        v-if="drugLink"
        class="drug_name"
        @click="$emit('drug-click', regimen.drug)"
        v-text="regimen.drug.name[$i18n.locale] || regimen.drug.name.en"
      />
      <span
        v-else
        class="drug_name"
        v-text="regimen.drug.name[$i18n.locale] || regimen.drug.name.en"
      />
      <v-dialog
        v-model="drugInfoDialog"
        color="white"
        :max-width="550"
        bottom
      >
        <!-- <template #activator="{ on }">
          <span
            class="drug_name"
            style="cursor: pointer"
            v-on="on"
          >
            {{ regimen.drug.name.en }}
          </span>
        </template> -->
        <template #activator="{ on }">
          <v-icon
            v-if="showDrugInfo"
            small
            class="mt-n1"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <template #default>
          <v-card xstyle="color:#000">
            <v-card-title>
              {{ regimen.drug.name[$i18n.locale] || regimen.drug.name.en }}
              <v-spacer />
              <v-btn
                icon
                tile
                color="grey"
                @click.stop="drugInfoDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <drug-info
                :drug="regimen.drug"
                :standard-dosage="regimen.standard_dosage"
                :pregnant="patient.pregnant"
                :breastfeeding="patient.breastfeeding"
              />
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      <v-img
        v-if="showArkscore"
        :src="`/img/arkscore-pill-${regimen.drug.score_adverse_reaction}.svg`"
        class="mx-1 mt-n1"
        style="display: inline-block; vertical-align: middle"
        width="90"
        contain
      />
      {{ regimen.dosage_and_duration || `${regimen.dosage} x ${regimen.duration}` }}
      <span
        v-if="showAdjustments(regimen)"
        class="red--text text--darken-4 font-weight-regular"
      >
        ({{ showAdjustments(regimen) }})
      </span>
      {{ $t(regimen.pivot.connector) }}
    </span>
    <template
      v-if="showIndication"
    >
      {{ $t('for possible', { indication: treatment.indication.indication[this.$i18n.locale] || treatment.indication.indication.en }) }}
    </template>
    <div
      v-if="disclaimers.length"
      class="text-caption font-italic"
    >
      * {{ disclaimers }}
    </div>
  </li>
</template>
<script>

export default {
  components: {
    DrugInfo: () => import('@/components/drug/DrugInfo.vue'),
  },
  props: {
    treatment: {
      type: Object,
      default: () => ({}),
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
    drugLink: {
      type: Boolean,
      default: true,
    },
    showArkscore: Boolean,
    showIndication: Boolean,
    showDrugInfo: Boolean,
  },
  data: () => ({
    drugInfoDialog: false,
  }),
  computed: {
    disclaimers () {
      // Remove duplication of disclaimers across regimens
      return this.treatment.regimens.map(r => r.disclaimer).join(' ').split(/(?<!\w\.\w.)(?<![A-Z][a-z]\.)(?<=\.|\?|!)\s/g).map(x => x.trim()).filter((item, pos, self) => self.indexOf(item) == pos).join(' ')
    },
    adjustmentParameter () {
      if (this.patient.dialysis) {
        return this.patient.dialysis
      } else if (this.patient.crcl) {
        return this.$t('CrCl')
      } else if (this.patient.gfr) {
        return this.$t('GFR')
      } else if (this.patient.hepatic_failure) {
        return this.$t('hepatic')
      } else {
        return null
      }
    },
  },
  watch: {
    '$i18n.locale' (lang) {
      if (lang === 'en') {
        this.treatment.regimens.forEach(r => this.$set(r, 'dosage_and_duration', null))
      }
      this.translateText(
        [...this.treatment.regimens].map(r => `${r.dosage} for ${r.duration}`),
        this.$i18n.locale,
        v => v.forEach((v, i) => this.$set(this.treatment.regimens[i], 'dosage_and_duration', v)),
      )
    },
  },
  created () {
    this.treatment.regimens.forEach(regimen => {
      regimen.drug.references = regimen.references
    });
  },
  methods: {
    showAdjustments(regimen) {
      if (!this.patient?.hepatic_failure && !this.patient?.crcl && !this.patient?.gfr && !this.patient?.dialysis) {
        return null
      }

      let filters = this.adjustmentFilter(this.getStandardDosage(regimen).adjustments).map(adjustment => {
        if (adjustment.no_adjustment) {
          return this.$t('no_adjustment_needed', { parameter: this.$t(adjustment.parameter) || this.$options.filters.capitalize(this.$t(adjustment.category)) })
        }
        return `${adjustment.parameter} adjustment: ${adjustment.dosage}`
      }).join(', ')

      return filters || this.$t('no_adjustments_available', { parameter: this.adjustmentParameter })
    },
    getStandardDosage (regimen) {
      return regimen.standard_dosage || regimen.drug.standard_dosages.find(x => x.default)
    },
    adjustmentFilter(adjustments) {
      let filtered_adjustments = []

      // Hepatic adjustments
      if (this.patient?.hepatic_failure) {
        filtered_adjustments = filtered_adjustments.concat(adjustments.filter(adjustment => {
          if (adjustment.category == 'hepatic') {
            return adjustment.parameter == this.patient.hepatic_failure || !adjustment.parameter
          }
        }))
      }

      // Renal adjustments
      if (this.patient?.dialysis) {
        filtered_adjustments = filtered_adjustments.concat(adjustments.filter(adjustment => {
          if (adjustment.parameter == this.patient.dialysis) {
            return true
          }
        }))
      } else if (this.patient?.crcl || this.patient?.gfr) {
        filtered_adjustments = filtered_adjustments.concat(adjustments.filter(adjustment => {
          if (this.patient?.gfr > 0) {
            if (adjustment.parameter == 'Renal') {
              return true
            } else if (adjustment.parameter != 'GFR' && adjustment.parameter != 'eGFR' && !this.patient.crcl) {
              return false
            } else {
                let modifier = adjustment.modifier
                if (modifier == '≥') {
                  modifier = '>='
                } else if (modifier == '≤') {
                  modifier = '<='
                }
              if (adjustment.modifier == 'range') {
                return this.patient.gfr >= adjustment.value[0] && this.patient.gfr <= adjustment.value[1]
              } else {
                return eval(`${this.patient.gfr} ${modifier} ${adjustment.value}`)
              }
            }
          }

          if (this.patient?.crcl > 0) {
            if (adjustment.parameter == 'Renal') {
              return true
            } else if (adjustment.parameter != 'CrCl') {
              return false
            } else {
              if (adjustment.modifier == 'range') {
                return this.patient.crcl >= adjustment.value[0] && this.patient.crcl <= adjustment.value[1]
              } else {
                let modifier = adjustment.modifier
                if (modifier == '≥') {
                  modifier = '>='
                } else if (modifier == '≤') {
                  modifier = '<='
                }
                return eval(`${this.patient.crcl} ${modifier} ${adjustment.value}`)
              }
            }
          }

          return true
        }))
      }
      return filtered_adjustments
    },
  },
}
</script>
<style>
.drug_name {
  font-weight: 500;
}
a.drug_name {
  text-decoration: underline;
  text-underline-offset: .15em;
  color: inherit;
}
</style>
